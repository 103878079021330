import { useState, useEffect } from 'react';
import { get } from '../../../utils/apiHandler';
import { Notifications_LastRefreshDate, Notifications_RefreshTimeInMinutes } from '../configuration';

export const useScheduleRefreshNotifications = () => {
    const [count, setCount] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getLastRefreshedDate = (): string | null => {
        const lastRefreshedDate = localStorage.getItem(Notifications_LastRefreshDate);
        return lastRefreshedDate ? JSON.parse(lastRefreshedDate) : null;
    };

    const resetCount = () => {
        setCount(0);
    };

    const fetchCount = () => {
        setIsLoading(true);
        const lastRefreshedDate = getLastRefreshedDate();

        get<number>(`notifications/count?lastRefreshedDate=${lastRefreshedDate ?? ""}`).then((res) => {
            setCount(res);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchCount();

        const timerId = setInterval(() => {
            fetchCount();
        }, (Notifications_RefreshTimeInMinutes * 60000));

        return () => {
            clearInterval(timerId);
        };
    }, []);

    return { count, resetCount, isLoading };
};