import axios, { AxiosResponse } from 'axios';
import { getAccessToken } from '../utils/access-token/get-access-token';

const apiUrl = process.env.REACT_APP_API_URL;
const responseBody = (response: AxiosResponse) => response.data;

export const getAuthorizationHeader = () => `Bearer ${getAccessToken()}`;

export const apiClient = axios.create({
    baseURL: `${apiUrl}/api/`,
    timeout: 60000,
    headers: {
        "Content-Type": "application/json",
        "Authorization": getAuthorizationHeader()
    }
});

// Use the latest access token in the Authroization header
apiClient.interceptors.request.use((config) => {
    if (config.headers) {
        config.headers['Authorization'] = getAuthorizationHeader();
    }
    return config;
})

export const get = async <T>(url: string, options = {}) => {
    return apiClient.get(url, options).then(responseBody).then((data: T) => data);
};

export const post = async <T>(url: string, data = {}, options = {}) => {
    return apiClient.post(url, data, options).then(responseBody).then((res: T) => res);
};