import styled from 'styled-components';
import { Icons } from '@sede-x/shell-ds-react-framework';

const Flexcontainer = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(30, 36, 43, 1);
  color: rgba(255, 255, 255, 0.5);
  & > div {
    margin: auto;
    width: 30%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    text-align: left;
    padding: 40px;
  }
`;

const Caption = styled.span`
  color: rgb(49, 115, 201);
  margin-bottom: 25px;
  font-size: 50px;
`;

const UnAuthorised = () => {
    return (
        <Flexcontainer>
            <div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <Caption><Icons.Lock/> Access Denied</Caption>
                        <br />
                        <p style={{ fontSize: '20px' }}>
                            You do not have any role to access the application.
                            <br />
                            <br />
                            Please contact Admin!
                        </p>
                    </div>
                </div>
            </div>
        </Flexcontainer>
    );
};

export default UnAuthorised;