import { combineReducers } from "redux";
import dashboardReducer from '../pages/Dashboard/state/reducer';

const reducers = combineReducers({
    dashboard: dashboardReducer
});

export default reducers;
export type AppRootState = ReturnType<typeof reducers>;


