import { useState, useCallback, useEffect } from 'react';
import { Icons, Menu, MenuItem, MenuItemGroup } from '@sede-x/shell-ds-react-framework';
import { useNavigate } from 'react-router-dom';
import routepaths from '../router/routepaths';
import styled from 'styled-components';

import Notifications from './Notifications';

const StyledMenu = styled(Menu)`
        && {
             .shell-menu-item-group-title {
              padding-top: 25px !important;
              font-size: 14px !important;
              font-weight: bold !important;
          } 
        }
    `;

export default function Sidebar() {
    const [currentPage, setCurrentPage] = useState<string>(window.location.pathname);
    const navigate = useNavigate();

    const onMenuLinkClick = useCallback((url: string) => {
        navigate(url);
    }, [navigate]);

    useEffect(() => {
        setCurrentPage(window.location.pathname);
    }, [window.location.pathname]);

    return (
        <>
            <StyledMenu size="small" defaultSelectedKeys={[currentPage]} onClick={({ key }) => setCurrentPage(key)}>
                <MenuItemGroup title="Dashboard" key="dashboard">
                    <MenuItem key={routepaths.dashboard_deal_planning} onClick={() => onMenuLinkClick(routepaths.dashboard_deal_planning)}>
                        <Icons.BarChartSquare />
                        Deal Planning
                    </MenuItem>
                    {/*<MenuItem key={routepaths.dashboard_power_bi} onClick={() => onMenuLinkClick(routepaths.dashboard_power_bi)}>*/}
                    {/*    <Icons.PieChartSolid />*/}
                    {/*    Power BI Dashboard*/}
                    {/*</MenuItem>*/}
                </MenuItemGroup>

                <MenuItemGroup title="Deal Management" key="dealManagement">
                    <MenuItem key={routepaths.deal_management_deal_summary} onClick={() => onMenuLinkClick(routepaths.deal_management_deal_summary)}>
                        <Icons.BarChartSquare />
                        Deal Summary
                    </MenuItem>
                    <MenuItem key={routepaths.deal_management_container_view} onClick={() => onMenuLinkClick(routepaths.deal_management_container_view)}>
                        <Icons.Table />
                        Container View
                    </MenuItem>
                    <MenuItem key={routepaths.deal_management_forecast_parcel_linking} onClick={() => onMenuLinkClick(routepaths.deal_management_forecast_parcel_linking)}>
                        <Icons.EditTable />
                        Forecast Parcel Linking
                    </MenuItem>
                    <MenuItem key={routepaths.deal_management_bl_parcel_management} onClick={() => onMenuLinkClick(routepaths.deal_management_bl_parcel_management)}>
                        <Icons.EditTable />
                        BL Parcel Linking
                    </MenuItem>
                </MenuItemGroup>

                <MenuItemGroup title="Useful Links" key="usefulLinks">
                    <MenuItem key="usefulLinks_user_manual" onClick={() => window.open(process.env.REACT_APP_LINKS_USERMANUAL)}>
                        <Icons.Database />
                        User Manual
                    </MenuItem>
                    <MenuItem key="usefulLinks_dms" onClick={() => window.open(process.env.REACT_APP_LINKS_DMS)} title="Document Managemnet System">
                        <Icons.CopyFile />
                        DMS
                    </MenuItem>
                    <MenuItem key="usefulLinks_tcs" onClick={() => window.open(process.env.REACT_APP_LINKS_TCS)} title="Terminal Connection System">
                        <Icons.Barge />
                        TCS
                    </MenuItem>
                </MenuItemGroup>
                <br />

                <Notifications />
            </StyledMenu>
        </>
    );
}

