import styled from 'styled-components';
import { Grid, Badge, Tabs as SdsTabs, RangeDatePicker } from '@sede-x/shell-ds-react-framework';


export const Container = styled.div`
    max-width: 75%;
    margin: 0 auto;
`;

export type FontSize = 'Small' | 'Medium' | 'Large';

export const BreadCrumbWrapper = styled.div <{ size?: string }>`
        padding-bottom: 0px;
        & a {
            border-bottom: none !important;
         &:hover { 
            border-bottom: none !important;
            transition: none !important;
         }
        }
        & .shell-breadcrumb{
            font-size: ${props => props.size ? props.size : '24px'} !important;
        }
        & svg{
            margin-top: 4px;
            width: 24px !important;
            height: 24px !important;
        }
    `;

export const Table = styled.table`
        border-collapse: separate;
        border-spacing: 20px;
        width: 100%;
        & td:nth-child(2) {
            float: right;
      }
    `;

export type StatBoxState = 'Neutral' | 'Negative' | 'Highlight';

const getBackgroundForStatBox = (state?: StatBoxState) => {

    if (state === 'Negative') {
        return 'rgb(175 38 64 / 20%)';
    } else if (state === 'Highlight') {
        return 'rgb(5 101 124 / 70%)';
    } else {
        return 'rgb(59 89 135 / 20%)'
    }
};

export const StatBox = styled(Grid) <{ state?: StatBoxState }>`
    box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
    border: 0.5px solid #babdc1;
    color: ${props => props.state === 'Highlight' ? 'white' : 'none'};
    background: ${props => getBackgroundForStatBox(props.state)};
    transition: background-color 500ms linear;
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    :hover {
        border: 0.5px solid lightgray;
        box-shadow: 0 4px 12px 0 rgb(34 36 38 / 12%), 0 4px 12px 0 rgb(34 36 38 / 15%);
    }
    `;

export const StatBody = styled.div`
    padding: 20px;
    cursor: pointer;
`;

export const StatTitle = styled.div`
    letter-spacing: 0.05em;
    font-size: 20px;
    min-height: 50px;
`;

export const StatValue = styled.div`
     padding-top: 50px;
     padding-bottom: 10px;
     font-size: 3em;
     font-Weight: bold;
`;

export const TableContainer = styled.div`
       width: 100%;
       overflow: auto;
    `;

export const StatusBadge = styled(Badge) <{width?: string }>`
       width: ${props => props.width ? props.width : ''};
       & span {padding: 5px;}
    `;

export const ButtonGroup = styled.div`
        display: flex;
        & .shell-button:nth-child(2) {
            margin-left: 20px;
      }
    `;

export const RangeDatePickerStyled = styled(RangeDatePicker)`
       & .shell-date-picker-range {height: 33px !important};
    `;

export const Tabs = styled(SdsTabs)`
  & .shell-tabs-nav {
    justify-content: flex-start !important;
  }

  & .shell-tabs-tab-active {
    background:  rgb(48 133 153 / 70%);
    color: white !important;
    border-radius: 6px !important;
   
    & path {fill: white;}
  }

  & .shell-tabs-tab-btn {border: none !important; }:focus {border: none !important; }

  & .shell-tabs-content-holder {
    margin-top: 10px;
    padding: 10px;
  }

  & .shell-tabs-nav-operations {display: none} 
`;