import { useState, useEffect, useCallback } from 'react';
import { Text, NavBar, AvatarWithText, Pecten, INavBarProps, Toggle } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../hooks/useUserProfile';

interface Props {
    onDarkModeChange?: (isChecked: boolean) => void;
}

interface AvatarProps {
    name: string;
    email: string;
}

const NavBarStyled = styled(NavBar)`
    padding: 2px 12px;
    & :nth-child(1) {
        justify-content: left !important; 
        align-items: center;
    }
    && svg {
      margin-top: 3px;
    }
    .shell-nav-bar { height: 55px !important;}
    `;

const ToggleStyled = styled(Toggle)`
    padding-right: 40px;
    `;

const AppTitle = styled.div`
    cursor: pointer;
    padding-left: 20px;
    & p {
        letter-spacing: 0.05em;
        font-size: 20px;
    }
    p: hover {
        color: gray;
    }
    `;

const AvatarWithTextStyled = styled(AvatarWithText)`
    padding-right: 20px;
    & .shell-icon {margin: 5px !important;}
    `;

const AvatarText = ({ name, email }: AvatarProps) => { return <AvatarWithTextStyled title={name} description={email} /> };

function Header({ onDarkModeChange }: Props) {

    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
    const userProfile = useUserProfile();

    const handleDarkModeChange = (checked: boolean) => {
        if (onDarkModeChange) {
            onDarkModeChange(checked);
        }

        setIsDarkMode(checked);
        localStorage.setItem("siteTheme", checked ? "dark" : "light");
    };

    const navbarArgs: INavBarProps = {
        leftArea: 'none',
        rightArea: <>{onDarkModeChange && <ToggleStyled label="Dark Mode" labelPosition="right" onChange={handleDarkModeChange} checked={isDarkMode} />} <AvatarText name={userProfile.username} email={userProfile.email} /></>,
        float: true
    };

    const navigate = useNavigate();

    const onMenuLinkClick = useCallback((url: string) => {
        navigate(url);
    }, [navigate]);

    useEffect(() => {
        const theme = localStorage.getItem("siteTheme");
        setIsDarkMode(theme === "dark");
    }, []);

    return (
        <NavBarStyled  {...navbarArgs}>
            <div>
                <a href="/" title="Home"><Pecten /></a>
            </div>
            <AppTitle onClick={() => onMenuLinkClick("/")}>
                <Text>
                    Contract Planning and Management
                </Text>
            </AppTitle>
        </NavBarStyled>
    );
}

export default Header;