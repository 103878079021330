export default {
    access_denied: '/access_denied',

    home: '/',
    dashboard_deal_planning: '/',
    dashboard_power_bi: '/dashboard/power-bi-dashboard',

    deal_management_deal_summary: '/deal-management/deal-summary',
    deal_management_container_view: '/deal-management/container-view',
    deal_management_forecast_parcel_linking: '/deal-management/forecast-parcel-linking',
    deal_management_bl_parcel_management: '/deal-management/bl-parcel-linking',

    documents_search: '/documents/search-parcels',
}
    