import React, { ReactNode, createContext, useState, useEffect, useContext } from 'react'
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { Loading, Text, Heading, Alert, ButtonLink, Icons } from '@sede-x/shell-ds-react-framework';
import { useScheduleRefreshToken } from '../hooks/useScheduleRrefreshToken';
import { getAccessToken } from '../utils/access-token/get-access-token';

import {
    LOCALSTORAGE_REFRESH_TOKEN,
    LOCALSTORAGE_ACCESS_TOKEN,
    LOCALSTORAGE_CODE_VERIFIER,
    LOCALSTORAGE_PREVIOUS_ROUTE,
    LOCALSTORAGE_USER
} from '../constants';
import { UserProfile } from '../hooks/useUserProfile';
import UnAuthorised from '../components/UnAuthorised';
import { apiClient } from '../utils/apiHandler';

export const StyledLoad = styled.div`
  background:  ${(props) => props.theme.background.surface};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  && svg {margin-right: 10px;}
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 80px;
    height: 80px;
    margin: -76px 0 0 -76px;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #0097bb;
    animation: ${spin} 0.5s linear infinite;
`;

const PINGID_CODE = 'code';
const PINGID_ERROR = 'error';

interface AuthContextType {
    token: string | null;
    ensureAuthenticated: () => void;
    userInfo: UserProfile;
};

type Props = {
    children: ReactNode;
};

const Context = createContext<AuthContextType>({ token: "", ensureAuthenticated: () => { }, userInfo: {} as UserProfile } as AuthContextType);

export function useAuth() {
    return useContext(Context);
}

export default () => {
    const { Provider } = Context;
    const [isUserUnauthorised, setUserUnauthorised] = useState<boolean | null>(null);
    const [userRoleLength, setUserRoleLength] = useState<number | null>(null);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [token, setToken] = useState<string | null>(null);
    const [userInfo, setUserInfo] = useState<UserProfile>({} as UserProfile);

    const getURLParameter = (name: string) => {
        const url = window.location.href;

        name = name.replace(/\[/, '\\[').replace(/\]/, '\\]');
        const regexS = `[\\?&]${name}=([^&#]*)`;
        const regex = new RegExp(regexS);
        const results = regex.exec(url);
        return results == null ? null : results[1];
    }

    const code = getURLParameter(PINGID_CODE);

    const pingError = getURLParameter(PINGID_ERROR);

    const getToken = async () => {
        if (code) {
            const savedCodeVerifier = window.localStorage.getItem(LOCALSTORAGE_CODE_VERIFIER);
            const codeVerifier = savedCodeVerifier ? JSON.parse(savedCodeVerifier) : null;

            if (codeVerifier) {

                const url = `${process.env.REACT_APP_PORTAL_API_URL}/api/auth/access-token`;

                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    method: 'POST',
                    body: new window.URLSearchParams({
                        code_verifier: codeVerifier,
                        code,
                        redirect_url: process.env.REACT_APP_REDIRECT_URL ?? "",
                        application_id: process.env.REACT_APP_APPLICATION_ID ?? ""
                    })
                })
                    .then(res => res.text())
                    .then(text => {
                        try {
                            const data = JSON.parse(text);
                            return data;
                        } catch (err) {
                            console.log('sso error: ' + text);
                            return null;
                        }
                    })
                    .catch((err) => {
                        setIsAuthenticating(false);
                        console.log('sso error: ' + err);
                    });

                if (response) {
                    setIsAuthenticating(false);
                    const { access_token, refresh_token, error, error_description, user } = response;

                    if (error) {
                        const isUserNotAuthorized = error === "access_denied" || (error_description && error_description.toLowerCase().indexOf("not authorized") > -1);
                        setUserUnauthorised(isUserNotAuthorized);

                        console.log(error);

                        //Re-validate if existing code expired
                        if (!isUserNotAuthorized && error_description.indexOf("expired") > -1) {
                            window.localStorage.setItem(LOCALSTORAGE_PREVIOUS_ROUTE, JSON.stringify(window.location.pathname));
                            window.location.replace('/');
                        }

                        if (isUserNotAuthorized) {
                            window.localStorage.removeItem(LOCALSTORAGE_ACCESS_TOKEN);
                            window.localStorage.removeItem(LOCALSTORAGE_REFRESH_TOKEN);
                            window.localStorage.removeItem(LOCALSTORAGE_CODE_VERIFIER);
                            window.localStorage.removeItem(LOCALSTORAGE_USER);
                            setUserInfo({} as UserProfile);

                            window.location.replace('/?error=access-denied');
                        }
                    }

                    if (!access_token && !refresh_token) {
                        return;
                    };

                    window.localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, JSON.stringify(access_token));
                    window.localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, JSON.stringify(refresh_token));
                    window.localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(user));

                    try {
                        const previousPath = window.localStorage.getItem(LOCALSTORAGE_PREVIOUS_ROUTE);
                        const path = previousPath ? JSON.parse(previousPath) : '/';

                        window.localStorage.removeItem(LOCALSTORAGE_PREVIOUS_ROUTE);
                        window.location.replace(path);
                    } catch (e) {
                        console.log(`login error: ${e}`);
                        window.location.replace('/');
                    }
                }
            }
        }
    };

    const createAuthorizeUrl = async (codeVerifier: string) => {

        try {
            const url = await axios.get<string>(`${process.env.REACT_APP_PORTAL_API_URL}/api/auth/sso-authorize-url?codeVerifier=${codeVerifier}&redirectUrl=${process.env.REACT_APP_REDIRECT_URL}`).then(res => {
                return res.data;
            });

            return url;
        } catch {
            return null;
        }
    };

    const getCodeVerifier = async () => {
        try {
            const codeVerifier = await axios.get<string>(`${process.env.REACT_APP_PORTAL_API_URL}/api/auth/generate-code-verifier`).then(res => {
                return res.data;
            });

            return codeVerifier;
        } catch {
            return null;
        }
    };

    const redirectToPing = async () => {
        const codeVerifier = await getCodeVerifier();
        window.localStorage.setItem(LOCALSTORAGE_CODE_VERIFIER, JSON.stringify(codeVerifier));

        const url = await createAuthorizeUrl(codeVerifier ?? "");
        window.localStorage.setItem("sso-url", JSON.stringify(url));
        window.localStorage.setItem(LOCALSTORAGE_PREVIOUS_ROUTE, JSON.stringify(window.location.pathname));

        if (url) {
            window.location.replace(url);
        }
    };

    const ensureAuthenticated = () => {
        const accessToken = getAccessToken();

        if (!accessToken && !isAuthenticating) {
            redirectToPing();
        }

        if (accessToken) {
            setIsAuthenticating(false);
            setToken(accessToken);
        }
    };

    const getUserDetails = () => {
        apiClient.get(`${process.env.REACT_APP_PORTAL_API_URL}/api/auth/user-details/access-token?applicationId=${process.env.REACT_APP_APPLICATION_ID ?? ""}`).then(res => {
            setUserRoleLength(res.data.roles.length ?? 0);
            if (res.data) {
                const { username, shortUsername, userId, email, roles } = res.data;
                setUserInfo({ username, shortName: shortUsername, userId, email, roles });
            }
        })
    }

    class Authenticated extends React.Component<Props> {
        componentDidMount() {
            ensureAuthenticated();
        }

        render() {
            const { children } = this.props;

            if (isUserUnauthorised != null && isUserUnauthorised) {
                return (
                    <div style={{ width: "50%", margin: "auto", paddingTop: "50px" }}>
                        <Alert state="error">
                            <Heading type="h3">Access Denied</Heading>
                            <Text>
                                You are not authorized to access this application.
                            </Text>
                        </Alert>
                        <ButtonLink icon={<Icons.ArrowLeftCircle />} iconPosition="left" size="small" href="/">Go Back</ButtonLink>
                    </div>
                );
            } else if (!token) {
                return (
                    <StyledLoad>
                        <Loading size="normal" />
                        <Text size="medium"> Authenticating user...</Text>
                    </StyledLoad>
                );
            } else if (userRoleLength != null && userRoleLength <= 0) {
                return <UnAuthorised />
            } else {
                return userRoleLength != null && userRoleLength > 0 ? children : <Loader></Loader>
            }
        }
    }

    return {
        AuthContext: ({ children }: { children: ReactNode }) => {
            const accessToken = getAccessToken();

            useScheduleRefreshToken();

            useEffect(() => {
                if (pingError) {
                    setUserUnauthorised(true);
                } else if (code) {
                    getToken();
                } else if (!accessToken) {
                    redirectToPing();
                }
                else if (accessToken && Object.keys(userInfo).length <= 0) {
                    getUserDetails();
                }
            }, [token]);

            return (
                <Provider value={{ token, ensureAuthenticated, userInfo }}>
                    {children}
                </Provider>
            )
        },
        Authenticated,
    }
};