export const LOCALSTORAGE_CODE_VERIFIER = 'code_verifier';
export const LOCALSTORAGE_ACCESS_TOKEN = 'access_token';
export const LOCALSTORAGE_REFRESH_TOKEN = 'refresh_token';
export const LOCALSTORAGE_PREVIOUS_ROUTE = 'previous_route';
export const LOCALSTORAGE_USER = 'user';
export const LOCALSTORAGE_NOTIFICATIONS = 'notifications';

export const LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED = 'PageHasBeenForceRefreshed';

//Usre Roles
export const UserRole_CommercialOperator = 'CPM_CommercialOperator';
export const UserRole_Admin = 'CPM_Admin';

//Date Format
export const DateFormat = 'DD MMM YYYY';
export const DateTimeFormat = 'DD MMM YYYY HH:mm';
export const InternationalDateFormat = 'YYYY-MM-DD';
