import { useAuth } from "../auth/createAuthContext";

export interface UserProfile {
    username: string;
    shortName: string;
    userId: string;
    email: string;
    roles: string[];
}

const createUserProfile = () => {
    const { userInfo } = useAuth();

    const userProfile: UserProfile = {
        username: userInfo.username,
        shortName: userInfo.shortName,
        email: userInfo.email,
        userId: userInfo.userId,
        roles: userInfo.roles,
    };

    return userProfile;
};

export const useUserProfile = (): UserProfile => {
    return createUserProfile();
};
