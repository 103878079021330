import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useAccessToken } from '../hooks/useAccessToken';
import { getAccessTokenData } from '../utils/access-token/get-access-token-data';
import {
    LOCALSTORAGE_ACCESS_TOKEN,
    LOCALSTORAGE_CODE_VERIFIER,
    LOCALSTORAGE_PREVIOUS_ROUTE,
    LOCALSTORAGE_REFRESH_TOKEN,
    LOCALSTORAGE_USER
} from '../constants';

const requestAccessToken = async (refreshToken: string, round = 0): Promise<AxiosResponse> => {

    const url = `${process.env.REACT_APP_PORTAL_API_URL as string}/api/auth/refresh-token`;

    const response = await axios.post(
        url,
        new URLSearchParams({
            refresh_token: refreshToken,
            application_id: process.env.REACT_APP_APPLICATION_ID || ""
        }),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
    );

    if (response?.data) {
        return response;
    } else if (round < 3) {
        return requestAccessToken(refreshToken, round + 1);
    } else {
        throw new Error('Could not request new token');
    }
};

const clearLocalStorageData = () => {
    window.localStorage.removeItem(LOCALSTORAGE_ACCESS_TOKEN);
    window.localStorage.removeItem(LOCALSTORAGE_REFRESH_TOKEN);
    window.localStorage.removeItem(LOCALSTORAGE_CODE_VERIFIER);
    window.localStorage.removeItem(LOCALSTORAGE_USER);
};

export const useScheduleRefreshToken = (): void => {
    const accessToken = useAccessToken();
    const [internalAccessToken, setInternalToken] = useState(accessToken);

    useEffect(() => {
        const accessTokenData = getAccessTokenData();

        if (accessTokenData) {
            // request new access token 3min before expiration
            const expirationMilliseconds = accessTokenData.exp * 1000;
            const time = expirationMilliseconds - 180000 - Date.now();

            if (time <= 0) {
                clearLocalStorageData();

                window.localStorage.setItem(LOCALSTORAGE_PREVIOUS_ROUTE, JSON.stringify(window.location.pathname));
                window.location.replace('/');
                return;
            }

            const timerId = setTimeout(async () => {
                let tokenRes = await requestTokens();
                if (tokenRes?.token) {
                    setInternalToken(tokenRes.token);
                }
            }, time);

            return () => {
                clearTimeout(timerId);
            };
        }
    }, [accessToken, internalAccessToken]);
};

const requestTokens = async () => {
    const refreshToken = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN) as string);

    if (!refreshToken) {
        return;
    }

    try {
        const response = await requestAccessToken(refreshToken, 0);

        if (!response?.data?.access_token && !response?.data?.refresh_token) {
            clearLocalStorageData();

            //temp test code
            if (process.env.REACT_APP_ENV === "dev") {
                alert("Login error: " + JSON.stringify(response));
            }

            //reload the page to trigger SSO
            window.location.reload();
            return;
        };

        // Update the latest access token in the Authroization header
        axios.interceptors.request.use((config) => {
            if (config.headers) {
                config.headers['Authorization'] = response.data.access_token;
            }
            return config;
        })

        window.localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, JSON.stringify(response.data.access_token));
        window.localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, JSON.stringify(response.data.refresh_token));
        window.localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(response.data.user));

        return { token: response.data.access_token, info: response.data.user };
    } catch (e) {
        console.error('Error', e);
        clearLocalStorageData();

        window.location.replace('/');
    }
}