import moment from 'moment';
import { StyledAlert } from '../styles';
import { Notification } from '../types';

type Props = {
    readonly data: Notification;
};

export function NotificationItem({ data } : Props) {
    
    return (
        <>
            <StyledAlert key={data.key} state={data.messageType == 'Alert' ? 'neutral' : 'information'}>{data.message}. <em>{moment(data.createdAt).format("lll")}</em></StyledAlert>
        </>
    );
}
