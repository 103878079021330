import styled from 'styled-components';
import { Alert, Tabs } from '@sede-x/shell-ds-react-framework';

export const NotificationsMenu = styled.div`
    margin-top: 40px;
    padding-left: 10px;
    color: rgba(117,117,117,1);
    font-size: 16px !important;
    font-weight: bold !important;
    cursor: pointer;
    `;

export const NotificationsMenuItem = styled.div`
    display: flex;
    align-items: center;
    && { 
        .shell-badge {margin-left: 10px; padding-left: 5px; padding-right: 10px;}
        svg { margin: 2px;}
        }
    `;

export const ClearNotificationBtnContainer = styled.div`
  padding-top: 50px;
  && a {float: right; }
`;

export const StyledAlert = styled(Alert)`
  margin-top: 10px;
`;

export const StyledTabs = styled(Tabs)`
  & .shell-tabs-nav {
    justify-content: flex-start !important;
  }; `
