import { takeLatest, call, put } from 'redux-saga/effects';
import { getStatusReportAsync } from './actions';
import { apiClient } from '../../../utils/apiHandler';

export function* getStatusReport(): any {
    try {
        const request = () => apiClient.get(`dashboard/status`);
        const res = yield call(request);

        yield put(getStatusReportAsync.success(res.data));

    } catch (err: unknown) {
        yield put(getStatusReportAsync.failure(err));
    }
}

export default function* getStatusReportSaga() {
    yield takeLatest(getStatusReportAsync.request, getStatusReport);
}