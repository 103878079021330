import React, { ReactElement, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Text } from '@sede-x/shell-ds-react-framework';

import routepaths from './routepaths';

const DealSummaryDashboardPage = React.lazy(() => import('../pages/DealSummary'));
const DetailedViewPage = React.lazy(() => import('../pages/ContainerView'));
const PowerBiDashboardPage = React.lazy(() => import('../pages/Dashboard/powerBIDashboard'));
const HomePage = React.lazy(() => import('../pages/Home'));
const ManageParcelsPage = React.lazy(() => import('../pages/ContainerManagement/ParcelLinking'));
const ForecastParcelLinkingPage = React.lazy(() => import('../pages/Forecast/ParcelLinking'));
const DocumentSearchPage = React.lazy(() => import('../pages/Documents/Search'));

function AppRouter(): ReactElement {
    return (
        <Suspense fallback={<Text>Loading...</Text>}>
            <Routes>
                <Route path={routepaths.home} element={<HomePage />} />
                <Route path={routepaths.deal_management_deal_summary} element={<DealSummaryDashboardPage />} />
                <Route path={routepaths.deal_management_container_view} element={<DetailedViewPage />} />
                <Route path={routepaths.dashboard_power_bi} element={<PowerBiDashboardPage />} />
                <Route path={routepaths.deal_management_bl_parcel_management} element={<ManageParcelsPage />} />
                <Route path={routepaths.deal_management_forecast_parcel_linking} element={<ForecastParcelLinkingPage />} />
                <Route path={routepaths.documents_search} element={<DocumentSearchPage />} />
            </Routes>
        </Suspense>
    );
}

export default AppRouter;
