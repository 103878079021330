import { useState } from 'react';
import { Icons, Badge, Drawer, Breadcrumb, TabPane, Loading } from '@sede-x/shell-ds-react-framework';
import { BreadCrumbWrapper } from '../../styledComponents';
import { NotificationItem } from './components/NotificationItem';

import { useGetNotifications, useScheduleRefreshNotifications } from './hooks';
import { NotificationsMenu, NotificationsMenuItem, StyledTabs } from './styles';

const NotificationTitle = () => (
    <BreadCrumbWrapper>
        <Breadcrumb
            items={[
                {
                    icon: <Icons.Bell />,
                    name: 'Notifications'
                }
            ]}
        />
    </BreadCrumbWrapper>
);

export default function Notifications() {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const { data, isLoading } = useGetNotifications(isDrawerOpen);
    const { count, resetCount } = useScheduleRefreshNotifications();

    return (
        <>
            <NotificationsMenu onClick={() => { setIsDrawerOpen(!isDrawerOpen); resetCount(); }}>
                <NotificationsMenuItem>
                    <span>
                        Notifications
                    </span>
                    <Badge
                        state={count && count > 0 ? "negative" : "positive"}
                        icon={<Icons.Bell />}
                    >
                        {count && count > 0 ? count : ""}
                    </Badge>
                </NotificationsMenuItem>
            </NotificationsMenu>

            <Drawer
                header={<NotificationTitle />}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                placement="right"
                width="580px"
            >
                <>
                    {isLoading &&
                        <Loading />
                    }

                    <StyledTabs>
                        <TabPane tab={<><Icons.BellAlertSolid /> Alerts ({data?.alerts?.length})</>} key="alerts">
                            <>
                                {
                                    data?.alerts?.map((item) => {
                                        return <NotificationItem data={item} key="alerts" />
                                    })
                                }
                            </>
                        </TabPane>
                        <TabPane tab={<><Icons.InfoCircle /> Info ({data?.information?.length})</>} key="information">
                            <>
                                {
                                    data?.information?.map((item) => {
                                        return <NotificationItem data={item} key="info"/>
                                    })
                                }
                            </>
                        </TabPane>
                    </StyledTabs>
                </>
            </Drawer>
        </>
    );
}
