import React, { memo } from 'react';
import { Icons, Alert, Accordion, AccordionPanel, Text, Breadcrumb } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

import { BreadCrumbWrapper, Container } from '../styledComponents';
import Header from './Header';

interface Props {
    error: string;
    info: string;
    uuid: string | null;
}

const PageContainer = styled.div`
    background:  ${(props) => props.theme.background.surface};
    height: 100vh;
    `;

function ErrorMessageView(props: Props) {
    const { error, info, uuid } = props;
    const renderHTML = (rawHTML: string) => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

    return (
        <PageContainer>
            <Header/>
            <br />
            <Container>
                <BreadCrumbWrapper>
                    <Breadcrumb
                        items={[
                            {
                                icon: <Icons.Alert />,
                                name: `Something went wrong. (Reference: ${uuid})`
                            }
                        ]}
                    />
                </BreadCrumbWrapper>
                <Alert state="error">
                    <p>{renderHTML(error)}</p>
                </Alert>
                <Accordion>
                    <AccordionPanel header="Technical Details">
                        <Text type="span">{renderHTML(JSON.stringify(info))}</Text>
                    </AccordionPanel>
                </Accordion>
            </Container>
        </PageContainer>
    );
}

export default memo(ErrorMessageView);
