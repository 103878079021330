import { getAccessToken } from './get-access-token';

type AccessToken = {
  aud: string;
  client_id: string;
  exp: number;
  givenName: string;
  iss: string;
  mail: string;
  scope: string[];
  sn: string;
  uid: string;
};

export const getAccessTokenData = (): null | AccessToken => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }
  try {
    const parsedAccessToken = JSON.parse(atob(accessToken.split('.')[1]));
    return parsedAccessToken;
  } catch (e) {
    return null;
  }
};
