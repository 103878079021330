import { Component, ReactNode, ErrorInfo } from 'react';
import ErrorMessageView from './ErrorMessageView';
import { post } from '../utils/apiHandler';
import { LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED} from '../constants';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error: string;
    info: string;
    uuid: string;
}

const retryPageLoading = () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        window.localStorage.getItem(LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED) || "false"
    ) as boolean;

    if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED, "true");
        return window.location.reload();
    } else {
        window.localStorage.setItem(LOCALSTORAGE_PAGE_HAS_BEEN_FORCE_REFRESHED, "false");
        return null;
    }
};

export default class GlobalErrorHandler extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: '',
            info: '',
            uuid: '',
        };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {

        const errorMessage = error.message;
        const uuidRef = window.crypto.randomUUID();
        const errorInfo = `${JSON.stringify(error, Object.getOwnPropertyNames(error))} at ${JSON.stringify(info)}`;

        // Display fallback UI
        this.setState({
            hasError: true,
            error: errorMessage,
            info: errorInfo,
            uuid: uuidRef,
        });

        post('/error-logs/log-ui-error', { error: errorMessage, info: errorInfo, uuid: uuidRef });

        if (errorMessage.indexOf("Loading chunk") > -1) {
            retryPageLoading();
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <ErrorMessageView error={this.state.error} info={this.state.info} uuid={this.state.uuid} />
                </>
            );
        }
        return this.props.children;
    }
}
