import { useState, useEffect } from 'react';
import { get } from '../../../utils/apiHandler';
import { NotificationResult } from '../types';
import { Notifications_LastRefreshDate } from '../configuration';

export const useGetNotifications = (fetchNow: boolean) => {
    const [data, setData] = useState<NotificationResult>({} as NotificationResult);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchData = () => {
        setIsLoading(true);

        get<NotificationResult>("notifications/list").then((res) => {
            setData(res);
            setIsLoading(false);
            window.localStorage.setItem(Notifications_LastRefreshDate, JSON.stringify(res.lastRefreshedDate));
        });
    };

    useEffect(() => {
        if (fetchNow) {
            fetchData();
        }
    }, [fetchNow]);

    return { data, isLoading };
};