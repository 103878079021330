import ActionTypes from './actionTypes';
import { Dashboard } from '../../../models/dashboard';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
    data: {} as Dashboard,
    loading: true,
};

function reconciledDataReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.STATUS_REPORT_REQUEST:
            return {
                data: {} as Dashboard,
                loading: true
            };
        case ActionTypes.STATUS_REPORT_SUCCESS:
            return {
                data: action.payload,
                loading: false
            };
        case ActionTypes.STATUS_REPORT_FAILED:
            return {
                data: {} as Dashboard,
                loading: false
            };
        default:
            return state;
    }
}

export default reconciledDataReducer;