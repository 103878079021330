import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled from 'styled-components';
import { ShellThemeProvider, ThemeMode, Grid } from '@sede-x/shell-ds-react-framework';
import store from './store/configureStore';
import Router from './router/Router';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import GlobalErrorHandler from './components/GlobalErrorHandler';
import createAuthContext from './auth/createAuthContext'

const PageContainer = styled(Grid)`
    background:  ${(props) => props.theme.background.surface};
    gap: 0px;
    `;

const Page = styled.div`
    display: flex;
    margin-top: 2px;
    min-height: 95vh;
    `;

const PageWrapper = styled.div`
    height: 100vh;
    `;

const SidebarWrapper = styled.div`
    padding: 0px;
`;

const StyledToastContainer = styled(ToastContainer)`
   &&&.Toastify__toast-container { width: auto;}
`;

const Content = styled.div`
    background:  ${(props) => props.theme.background.base};
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px;
    `;

function App() {
    const { AuthContext, Authenticated } = createAuthContext();
    const [siteTheme, setSiteTheme] = useState<ThemeMode>("light");
    const onDarkModeChange = (isSelected: boolean) => {
        setSiteTheme(isSelected ? "dark" : "light");
    };

    useEffect(() => {
        const theme = localStorage.getItem("siteTheme");
        setSiteTheme(theme === "dark" ? "dark" : "light");
    }, []);

    localStorage.setItem('Build number', `${process.env.REACT_APP_BUILD_NUMBER}`);

    return (
        <ReduxProvider store={store}>
            <ShellThemeProvider theme={siteTheme}>
                <BrowserRouter>
                    <GlobalErrorHandler>
                        <AuthContext>
                            <Authenticated>
                                <PageContainer>
                                    <PageWrapper>
                                        <Header onDarkModeChange={onDarkModeChange} />
                                        <Page>
                                            <SidebarWrapper>
                                                <Sidebar />
                                            </SidebarWrapper>
                                            <Content>
                                                <StyledToastContainer position="top-center" />
                                                <Router />
                                            </Content>
                                        </Page>
                                    </PageWrapper>
                                </PageContainer>
                            </Authenticated>
                        </AuthContext>
                    </GlobalErrorHandler>
                </BrowserRouter>
            </ShellThemeProvider>
        </ReduxProvider>
    );
}

export default App;
